import "./vh";

import { createElement } from "react";
import ReactDOM from "react-dom";

import { APPLICATION_URL } from "./conf";
import App from "./pages/_app";
import Outdated from "./pages/outdated";

async function main() {
  if (window.top === window.self) {
    const documentUrl = new URL(document.URL);
    const redirectUrl = new URL(documentUrl.pathname, APPLICATION_URL);
    redirectUrl.search = documentUrl.search;
    window.location.replace(redirectUrl.toString());
  }

  const isOutdated =
    typeof Promise === "undefined" ||
    !("finally" in Promise.prototype) ||
    typeof window.URLSearchParams === "undefined";

  if (isOutdated) {
    return ReactDOM.render(
      createElement(Outdated),
      document.getElementById("root"),
    );
  }

  const polyfills: Promise<unknown>[] = [];

  if (
    !("IntersectionObserver" in window) ||
    !("IntersectionObserverEntry" in window) ||
    !("intersectionRatio" in window.IntersectionObserverEntry.prototype) ||
    !("isIntersecting" in window.IntersectionObserverEntry.prototype)
  ) {
    console.debug("Loading IntersectionObserver polyfill...");
    polyfills.push(import("intersection-observer"));
  }

  if (!("flatMap" in Array.prototype)) {
    console.debug("Loading flatMap polyfill...");
    polyfills.push(import("core-js/features/array/flat-map"));
  }

  if (!("flat" in Array.prototype)) {
    console.debug("Loading flat polyfill...");
    polyfills.push(import("core-js/features/array/flat"));
  }

  if (!("at" in Array.prototype)) {
    console.debug("Loading at polyfill...");
    polyfills.push(import("core-js/features/array/at"));
  }

  if (!("at" in String.prototype)) {
    console.debug("Loading at polyfill...");
    polyfills.push(import("core-js/features/string/at"));
  }

  if (!("fromEntries" in Object)) {
    console.debug("Loading fromEntries polyfill...");
    polyfills.push(import("core-js/features/object/from-entries"));
  }

  if (!("scrollBehavior" in document.documentElement.style)) {
    console.debug("Loading smoothscroll polyfill...");
    polyfills.push(
      import("smoothscroll-polyfill").then(({ polyfill }) => polyfill()),
    );
  }

  if (polyfills.length > 0) {
    await Promise.all(polyfills);
  }

  ReactDOM.render(createElement(App), document.getElementById("root"));
}

main();
